<template>
    <div style="background:#F6F9FE;height:100%;padding-bottom:20px;">
        <div style="background: #fff;">
            <div class="head">
                <div class="">
                    <img style="width:171px ;height:34px;" src="../../assets/images/login/hrlogo.svg" alt="">
                </div>
                <div class="" style="display:flex;align-items: center;">
                    <p style="">已有账号，去<a href="/home/main#/login" style="color: #3CADFB;">登录
                        </a></p>
                    <i style="    height: 21px;
    line-height: 23px;" class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="type_area" style="background:#FFF;padding:40px 110px;">
            <div>
                <div>
                    <Steps :steps="steps" :width="'100%'" :descMaxWidth="160" :current="current" @change="onChange" />
                </div>
                <div>
                    <div class="yhdRegister" v-if="current===1">
                        <div class="register">
                            <div class="register-tit">基本信息填写（<span style="color:red;">*</span>为必填）</div>
                            <div class="register-form" style="width:500px;">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                                    <el-form-item label="企业类型" prop="company_type">
                                        <el-select v-model="ruleForm.company_type" placeholder="请选择企业类型" style="width: 100%;">
                                            <el-option label="企业" value="企业"></el-option>
                                            <el-option label="个人" value="个人"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- <el-form-item label="企业名称" prop="company_name" >
                                    <el-input v-model="ruleForm.company_name" placeholder="请填写营业执照上的名称"></el-input>
                                </el-form-item> -->
                                    <el-form-item label="登录用户名" prop="username">
                                        <el-input v-model="ruleForm.username" placeholder="请输入6-16位字符，包含英文和数字"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机号" prop="phone">
                                        <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
                                    </el-form-item>
                                    <el-form-item label="短信验证码" prop="verification">
                                        <div class="verification">
                                            <el-input v-model="ruleForm.verification" placeholder="请输入验证码"></el-input>
                                            <div v-if="time" class="verification-btn">{{time}}s后再获取</div>
                                            <div v-else class="verification-btn" @click="getCode">短信验证码</div>
                                        </div>
                                        <!-- <div class="verificationTip">*每天仅可以获取验证码6次,10分钟内仅可以获取3次 </div> -->
                                    </el-form-item>
                                    <el-form-item label="请设置密码" prop="password">
                                        <el-input v-model="ruleForm.password" placeholder="请输入6-20位字符密码" auto-complete="new-password" type="password"></el-input>
                                    </el-form-item>
                                    <el-form-item label="请确认密码" prop="repeatword">
                                        <el-input v-model="ruleForm.repeatword" placeholder="请再次输入密码" type="password"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系人姓名" prop="name">
                                        <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="固定电话">
                                        <el-input v-model="ruleForm.fixedPhone" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系人邮箱 ">
                                        <el-input v-model="ruleForm.email" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item prop="userAgree" label-width="200" class="user-item">
                                    <template slot="label">
                                    </template>
                                </el-form-item> -->
                                </el-form>
                            </div>
                            <div style="padding-left:100px;">
                                <div style="margin-bottom:10px;display:flex;align-items: center;" class="user-item">
                                    <el-checkbox :value="ruleForm.userAgree" @change="handleRadioChange" style="margin-right:10px;"></el-checkbox>
                                    <span @click="doAgree" style="font-size: 14px;">我已阅读并同意</span>
                                    <el-link :underline="false" type="primary" class="user-agree" @click="doAgree">《服务协议》</el-link>
                                    <el-link :underline="false" type="primary" class="user-agree" @click="doAgree">《隐私协议》</el-link>
                                </div>
                                <el-button v-if="!this.ruleForm.userAgree&&!this.ruleForm.name&&!this.ruleForm.password&&!this.ruleForm.repeatword&&!this.ruleForm.phone&&!this.ruleForm.verification" style="background-color:#8cceff;opacity: 0.5;  width: 398px;cursor: not-allowed;color:#fff;">下一步</el-button>
                                <el-button v-else class="register-btn" @click="next(2)">下一步</el-button>
                            </div>
                        </div>
                    </div>
                    <div class="yhdRegister" v-if="current===2">
                        <div class="register">
                            <div class="register-tit">企业信息填写（<span style="color:red;">*</span>为必填）</div>
                            <div class="register-form" style="width:500px;">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                                    <el-form-item label="企业名称" prop="username">
                                        <el-input v-model="ruleForm.username" placeholder="请输入6-16位字符，包含英文和数字"></el-input>
                                    </el-form-item>
                                    <el-form-item label="企业所在地" prop="company_type">
                                     
                                          <el-cascader
    v-model="ruleForm.company_type"
    :options="options"
    ></el-cascader>
                                    </el-form-item>
                                    <!-- <el-form-item label="企业名称" prop="company_name" >
                                    <el-input v-model="ruleForm.company_name" placeholder="请填写营业执照上的名称"></el-input>
                                </el-form-item> -->

                                    <el-form-item label="企业地址" prop="phone">
                                        <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
                                    </el-form-item>

                                    <el-form-item label="企业负责人">
                                        <el-input v-model="ruleForm.fixedPhone" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="邀请人">
                                        <el-input v-model="ruleForm.email" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item prop="userAgree" label-width="200" class="user-item">
                                    <template slot="label">
                                    </template>
                                </el-form-item> -->
                                </el-form>

                            </div>
                            <div class="register-tit">基本信息填写（<span style="color:red;">*</span>为必填）</div>
                            <div style="margin-bottom:10px;display:flex;align-items: center;">
                                <div v-for="(item,index) in qualification" :key="index" style="margin-right:40px;" @click="getIndex(index)">
                                    <div style="margin-bottom:10px;"><span style="color:red;">*</span> {{item.name}}</div>
                                    <el-upload v-if="!item.list.length" :show-file-list="false" action="" :auto-upload="false" :on-change="picChange" :file-list="item.list">
                                        <div class="upload-demo">
                                            <img style="width:86px;height:86px;" src="../../assets/images/login/camera.svg" alt="">
                                        </div>
                                    </el-upload>
                                   <div v-else style="width:86px;height:86px;" class="imgList"  >
                                     <img style="width:86px;height:86px;" :src="item.list[0].url" alt="">
                                       <i  @click="delImg(item)" class="el-icon-error"></i>
                                   </div>
                                </div>
                            </div>
                            <div style="padding-left:100px;">
                                <div>
                                    <el-button class="next-btn">下一步</el-button>
                                    <el-button class="register-btn" @click="next(3)">提交</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="yhdRegister" v-if="current===3">
                         <div style="width: 1000px;height: 477px;background: #F8F8F8;margin:40px auto 70px;padding-top:130px;font-size: 22px;color: #01C700;">
                             <div style="margin:0px auto ;width:170px;height:100px;"><img src="../../assets/images/login/reg-success.png" alt=""></div>
                             <div style="text-align:center;margin-top:20px;">
                                您已注册成功！请等待后台审核!
                             </div>
                             <div style="text-align:center;">
                                  审核通过才可登录此账号...
                             </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="agreeVisible" width="70%" class="abow_dialog" :close="handClose" :before-close="handClose">
            <span v-html="agreeContent"> </span>
            <div style="display: flex;
                        justify-content: center;
                        align-items: center;margin-bottom: 10px;"><el-button class="agreeBtn" :disabled="disabled" type="primary" @click="agreeUser">同意</el-button></div>

        </el-dialog>
    </div>
</template>

<script>
import Steps from './component/step.vue'
const validateCompanyType = (rule, value, callback) => {
    console.log(value);
    if (value == '') {
        callback(new Error('请选择企业类型'))
    } else {
        callback()
    }
}
export default {
    components: {
        Steps
    },
    data() {
        return {
            steps: [
                {
                    title: '用户注册',
                    description: '完善基本信息资料'
                },
                {
                    title: '提交资质',
                    description: '上传要点相关资质等'
                },
                {
                    title: '注册完成',
                    description: '注册完成'
                },
            ],
            current: 1,
            // agreeVisible: false,
            agreeVisible: false,
            visible: false,
            options:[],
            time: 0,
            ruleForm: {
                company_name: '',
                company_type: '',
                name: '',
                password: '',
                repeatword: '',
                phone: '',
                username: '',
                verification: '',
                fixedPhone: '',
                email: '',
                userAgree: false,
            },
            qualification: [
                {
                    name: '营业执照',
                    url: '',
                    list: [],
                },
                {
                    name: '经营许可证',
                     list: [],
                    url: ''
                },
                {
                    name: '委托采购书',
                     list: [],
                    url: ''
                },
                {
                    name: '身份证(人面像)',
                     list: [],
                    url: ''
                },
                {
                    name: '身份证(国徽像)',
                     list: [],
                    url: ''
                },
                {
                    name: '医疗器械经营许可证:',
                     list: [],
                    url: ''
                },
                {
                    name: '食品经营许可证',
                     list: [],
                    url: ''
                },

            ],
            currentIndex:0,
            agreeFlag: false,
            disabled: true,
            timer: null,
            agreeContent: '',
            rules: {
                company_name: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' },
                    // {
                    //     validator: validateCompanyName, trigger: 'blur'
                    // }
                ],
                company_type: [
                    { required: true, message: '请选择企业类型', trigger: 'blur' },
                    {
                        validator: validateCompanyType, trigger: 'change'
                    }
                ],

                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    {
                        min: 6,
                        max: 15,
                        message: '长度在 6 到 15 个字符',
                        trigger: 'blur',
                    },
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },

                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 20,
                        message: '长度在 6 到 20 个字符',
                        trigger: 'blur',
                    },
                ],
                repeatword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 20,
                        message: '长度在 6 到 20 个字符',
                        trigger: 'blur',
                    },
                    // { validator: validatePass, trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    {
                        // validator: validatePhone, trigger: 'blur'
                    }, {
                        min: 11, max: 11, trigger: 'blur', message: '手机号长度为11位'
                    }
                ],
                verification: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                // userAgree:[
                // { required: true, validator: validatorType, trigger: 'change' }
                // ]
            },
        }
    },
    methods: {
        handleRadioChange() {
            this.ruleForm.userAgree = !this.ruleForm.userAgree
        },
        doAgree() {

        },
        handClose() {

        },
        agreeUser() {

        },
        next(next){
            this.current=next
        },
        picChange(file) { 
               const reader = new FileReader();
            reader.onload = e => {
                // 将读取的结果赋值给 imageUrl
                const imageUrl = e.target.result;
                 this.qualification[this.currentIndex].list.pop()
                   this.qualification[this.currentIndex].list.push({url:imageUrl})
            };
            // 读取文件内容
            reader.readAsDataURL(file.raw);
       console.log(this.qualification,'this.qualification');
        },
        getIndex(index) { 
            console.log(index,'index');
            this.currentIndex=index
        },
        delImg(item) { 
          item.list.pop()
        },
        onChange(index) {
            this.current = index
        },
        getCode() {
            this.time = 60
            this.timer = setInterval(() => {
                this.time--
                if (this.time <= 0) {
                    clearInterval(this.timer)
                }
            }, 1000);
        }
    }
}
</script>
 
<style lang="scss" scoped>
.yhdRegister .register-form .verification {
    display: flex;
}
.imgList{
        position: relative;
  i{
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.register-btn {
    background: #1a9dff;
    color: #fff;
    width: 398px;
    &:hover {
        background: #337cff;
    }
}
.next-btn {
    background: #fff;
    color: #333;
    width: 398px;
    &:hover {
        color: #1a9dff;
        border-color: #1a9dff;
    }
}

::v-deep .el-checkbox__input .el-checkbox__inner {
    border-radius: 50% !important;
}

.yhdRegister .register-form .verification-btn {
    position: absolute;
    cursor: pointer;
    right: 15px;
    color: #1a9dff;
    &:hover {
        color: #337cff;
    }
}
.yhdRegister {
    ::v-deep.el-form-item {
        display: flex;
        ::v-deep.el-input {
            width: 400px;
        }
    }
    ::v-deep.el-form-item__content {
        margin: 0 !important;
    }
    ::v-deep .el-form-item__label {
        text-align: right !important;
    }
}
.head {
    width: 1312px;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left {
    float: left;
}
.right {
    float: right;
}
.right a {
    color: #3cadfb !important;
}
.h56 {
    height: 40px;
    line-height: 40px;
}
.disabled-btn {
    pointer-events: none;
    font-size: 12px;
}
// body {
//     overflow-y: visible !important;

// }
// body::-webkit-scrollbar-thumb{
//     background: #fff !important;
// }
.user-agree {
    vertical-align: top;
    cursor: pointer;
    color: #1a9dff;
}
.user-item .el-form-item__label {
    position: relative;
    z-index: 999;
}
::v-deep.el-dialog__wrapper {
    overflow: visible !important;
}
.abow_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
}
.abow_dialog .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    overflow: hidden;
}
.abow_dialog .el-dialog .el-dialog__body {
    position: absolute;
    left: 0;
    top: 54px;
    bottom: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
}
.register-tit {
    background: #f7f8fa;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #f5f5f5;
    background: #f7f8fa;
    padding: 10px 20px;
    font-size: 16px;
    color: #333333;
    margin: 25px 0;
}
</style>